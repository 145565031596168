<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="addDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.addDocumentClauseFilter') }}</v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="filterForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.filter') }}</v-flex>
                        <v-flex xs6 lg9 md7 py-1>
                            <v-select
                                    :items="filterOptions"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="SdaFilter.id"
                                    item-text="SdaFilter.title"
                                    solo
                                    v-model="filterOption"
                                    @change="filterOptionChanged()"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="filterOption != null">
                        <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.value') }}</v-flex>
                        <v-flex xs6 lg9 md7 py-1>
                            <v-autocomplete
                                    :items="valueOptions"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="id"
                                    item-text="title"
                                    solo
                                    v-model="valueOption"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="addDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addClauseFilter()"
                >{{ $t('message.add') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";

export default {
    name: "AddClauseFilter",
    props: ['clauseId','dialog','dialog-closed','create-done','pageKey'],
    data() {
        return {
            add_dialog: false,
            filterOption: null,
            filterOptions: [],
            loading: {
                add: false,
                get: false,
                save: false
            },
            validForm: true,
            valueOption: null,
            valueOptions: []
        }
    },
    computed: {
        addDialog: {
            get() {
                return this.add_dialog;
            },
            set(value){
                this.add_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        addClauseFilter() {
            if (this.$refs.filterForm.validate()) {
                this.loading.add = true
                this.createClauseFilter()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.clauseFilterAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.clauseFilterNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.clauseFilterNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            }
        },
        createClauseFilter() {
            let filter = {
                clause: this.clauseId,
                filter: this.filterOption,
                value: this.valueOption
            }
            return new Promise((resolve, reject) => {
                api
                    .post('/sdadocuments/filter',{
                            filter: filter
                        }
                    )
                    .then((response) => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(()=>{
                        resolve('error')
                    })
            })
        },
        filterOptionChanged() {
            if(this.filterOption != null){
                this.loading.get = true
                this.getValueOptions()
                    .then(() => {
                        this.loading.get = false
                    })
                    .catch(() => {
                        this.loading.get = false
                    })
            }
        },
        getFilterOptions() {
            return new Promise((resolve, reject) => {
                api
                    .get("/sdadocuments/filter-options")
                    .then(response => {
                        this.filterOptions = []
                        this.filterOptions = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getValueOptions() {
            return new Promise((resolve, reject) => {
                api
                    .get("/sdadocuments/filter-value-options/" + this.filterOption)
                    .then(response => {
                        this.valueOptions = []
                        this.valueOptions = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                this.getFilterOptions()
                    .then(() => {
                        this.filterOption = 1
                        this.getValueOptions()
                            .then(() => {
                                this.loading.get = false
                            })
                            .catch(() => {
                                this.loading.get = false
                            })
                    })
            } else {
                this.valueOption = null
                this.valueOptions = []
                this.filterOption = null
                this.filterOptions = []
            }
            this.add_dialog = value
        }
    }
}
</script>

<style scoped>

</style>